import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { ThemeService } from "./theme/components/theme.service";
import { MessageBroadcasterService } from "./services/message.service";
import { AuthenticationService } from "./services/authentication.service";
import { Router, NavigationEnd } from "@angular/router";
import { SocketsService } from "./sockets.service";
import { LanguageService } from "./core/language.service";
import { Socket } from "ngx-socket-io";

declare let $: any;
// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          "margin-left": "280px",
        })
      ),
      state(
        "out",
        style({
          "margin-left": "0px",
        })
      ),
      transition("in => out", [animate("200ms ease-in")]),
      transition("out => in", [animate("200ms ease-in")]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Raise";
  isLoaderActive = true;
  checkedCriticalEvents = false;
  isLoggedIn = false;
  showChangePasswordPopUp = false;
  changedLogin!: boolean;

  constructor(
    private translate: TranslateService,
    private themeService: ThemeService,
    private messageService: MessageBroadcasterService,
    private authService: AuthenticationService,
    private router: Router,
    private service: SocketsService,
    private languageService: LanguageService,
    private socket: Socket
  ) {
    setInterval(() => {
      try {
        this.socket.emit("active");
      } catch { }
    }, 60000);
  }

  ngOnInit(): void {
    this.languageService.setDefaultLanguage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { });

    this.service.initSocketSubscriptions();

    this.authService.loginSuccess.subscribe(() => {
      if (!this.authService.getChangedLogin()) {
        this.showChangePasswordPopUp = true;
      }
      this.isLoggedIn = true;
      this.service.authenticateSocketIo();
      this.checkedCriticalEvents = false;
      // this.checkCriticalEvents();
    });
    if (this.authService.isUserAuthenticated()) {
      if (!this.authService.getChangedLogin()) {
        this.showChangePasswordPopUp = true;
      }

      this.isLoggedIn = true;
      this.service.authenticateSocketIo();
      // this.checkCriticalEvents();
    }

    this.authService.logoutSuccess.subscribe((data) => {
      this.isLoggedIn = false;
      this.service.logoutSockets(data);
    });

    this.router.events.subscribe((x) => {
      if (x instanceof NavigationEnd) {
        // gtag("config", "G-THVF52ES6Y", {
        //   page_path: (x as NavigationEnd).urlAfterRedirects,
        // });
        const xx = x as NavigationEnd;
        if (xx.url === "/chat") {
          this.isLoaderActive = false;
        } else {
          this.isLoaderActive = true;
        }
      }
      window.scrollTo(0, 0);
    });

    // this.messageService.patientCriticalEvent.subscribe((x) => {
    //   this.toastService.warninngMessage(
    //     `${this.translate.instant("general.Patient")} ${x.data.patientName} ${this.translate.instant(
    //       "general.hadACriticalEvent"
    //     )}`
    //   );
    // });
  }

  ngOnDestroy() {
    this.messageService.patientCriticalEvent.unsubscribe();
  }

  get isOpen() {
    return this.themeService.animationState;
  }

  // checkCriticalEvents() {
  //   if (!this.checkedCriticalEvents && this.authService.hasRole(Role.Doctor)) {
  //     this.notificationService
  //       .getAllNotifications(false, undefined, NotificationTypes.dssRule)
  //       .subscribe((notifications: any) => {
  //         console.log("notifications.unread:", notifications.unread);
  //         if (notifications && notifications.unread > 0) {
  //           this.toastService.confirmDeleteWithMessage(
  //             this.translate.instant(`notificationsPage.criticalNotification`, {
  //               unreadCriticalEvents: notifications.unread,
  //             }),
  //             () => this.router.navigateByUrl("critical-notifications")
  //           );
  //         }
  //         this.checkedCriticalEvents = true;
  //       });
  //   }
  // }
}
