import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppResolver implements Resolve<any> {
  patientRestrictedAccess!: boolean;

  constructor(private httpClient: HttpClient) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const deploymentSettings = await this.httpClient
      .get<any>(`${environment.serverPath}/api/deployment-settings`)
      .toPromise();

    environment.deploymentSettings = deploymentSettings;

    return environment.deploymentSettings;
  }
}
