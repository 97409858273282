<div #pdf style="width: 210mm" class="pdf-template container-fluid">
  <!-- HEADER SECTION -->
  <div
    class="summary-info-section d-flex align-items-center"
    style="border-bottom: 1px solid black; padding-bottom: 10px"
  >
    <img src="/assets/images/favicon.png" alt="" style="width: 60px; height: 50px" />
    <div class="flex-grow-1" style="text-align: center; font-size: 20px; margin-left: -60px">
      {{ "patientReport" | translate }}
    </div>
  </div>

  <!-- DEMOGRAPHICS SECTION -->
  <ng-container>
    <div class="summary-info-section" style="padding: 10px 0 20px 0">
      <div class="d-flex justify-content-between">
        <div>
          <div *ngIf="patientInfo.Fullname" class="d-flex">
            <label class="summary-key" style="align-self: center">{{ "exportPdfPage.fullName" | translate }}: </label>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">
              <strong>{{ patientInfo.Fullname }}</strong>
            </div>
          </div>
          <div class="p-1"></div>
          <div *ngIf="patientInfo.DateOfBirth" class="d-flex">
            <label class="summary-key">{{ "exportPdfPage.birthDate" | translate }}: </label>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">
              {{ patientInfo.DateOfBirthLabel }}
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="patientInfo.Phone" class="d-flex">
            <label class="summary-key">{{ "exportPdfPage.phone" | translate }}: </label>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ patientInfo.Phone }}</div>
          </div>
          <div class="p-1"></div>
          <div *ngIf="patientInfo.Email" class="d-flex">
            <label class="summary-key">{{ "exportPdfPage.email" | translate }}: </label>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ patientInfo.Email }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- PROCEDURES SUBSECTION -->
  <div
    *ngIf="pdfModel.procedures && pdfModel.procedures.length > 0"
    class="summary-info-section"
    style="border-bottom: 1px solid black"
  >
    <label style="font-size: 18px; font-weight: 500; padding: 0 20px; margin-bottom: 5px">
      {{ "exportPdfPage.procedures" | translate }}
    </label>
  </div>
  <div
    *ngIf="pdfModel.procedures && pdfModel.procedures.length > 0"
    style="margin-bottom: 10px"
    class="summary-info-section"
  >
    <div class="row">
      <ng-container *ngFor="let item of pdfModel.procedures">
        <div class="medical-history-item col-md-6" style="padding: 10px 15px">
          <div class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.Category" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.categoryLabel }}</div>
          </div>
          <div class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.procedure" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.codeLabel }}</div>
          </div>
          <div *ngIf="item.dateLabel" class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.performedDate" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.dateLabel }}</div>
          </div>
          <div *ngIf="item.reasonLabel" class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.reason" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.reasonLabel }}</div>
          </div>
          <div *ngIf="item.noteLabel" class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.notes" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.noteLabel }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- MEDICATION SUBSECTION -->
  <div
    *ngIf="pdfModel.medication && pdfModel.medication.length > 0"
    class="summary-info-section"
    style="border-bottom: 1px solid black"
  >
    <label style="font-size: 18px; font-weight: 500; padding: 0 20px; margin-bottom: 5px">
      {{ "exportPdfPage.medication" | translate }}
    </label>
  </div>
  <div
    *ngIf="pdfModel.medication && pdfModel.medication.length > 0"
    style="margin-bottom: 10px"
    class="summary-info-section"
  >
    <div class="row">
      <ng-container *ngFor="let item of pdfModel.medication">
        <div class="medical-history-item col-md-6" style="padding: 10px 15px">
          <div class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.medication" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.medicationLabel }}</div>
          </div>
          <div class="d-flex gap-3">
            <div class="summary-key">{{ "exportPdfPage.period" | translate }}:</div>
            <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.periodLabel }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- CAREPLAN SUBSECTION -->
  <div
    *ngIf="pdfModel.carePlan && pdfModel.carePlan.length > 0 && showCarePlan == true"
    style="margin-bottom: 20px"
    class="summary-info-section"
  >
    <div style="border-bottom: 1px solid black">
      <label style="font-size: 18px; font-weight: 500; padding: 0 20px; margin-bottom: 5px">
        {{ "exportPdfPage.carePlan" | translate }}
      </label>
    </div>
  </div>
  <ng-container *ngIf="pdfModel.carePlan && pdfModel.carePlan.length > 0">
    <ng-container *ngFor="let category of pdfModel.carePlan">
      <div *ngIf="category.showCategory === true" class="summary-info-section">
        <label
          style="
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid rgba(143, 143, 143, 0.847);
            padding: 0 20px;
            margin: 10px 0 5px 0;
          "
        >
          {{ category.categoryLabel }}
        </label>
        <div class="row">
          <ng-container *ngFor="let item of category.values">
            <div
              *ngIf="item.show === true"
              style="padding: 10px 15px"
              [ngClass]="category.type === 'Education' ? 'col-md-12' : 'col-md-6'"
            >
              <div class="d-flex gap-3">
                <!-- <div class="summary-key">{{ "exportPdfPage.medication" | translate }}:</div> -->
                <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.label }}</div>
              </div>
              <ng-container *ngIf="category.type !== 'Laboratory'">
                <div class="d-flex gap-3">
                  <div class="summary-key">{{ "exportPdfPage.period" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.rangeLabel }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="category.type === 'Laboratory'">
                <div class="d-flex gap-3">
                  <div class="summary-key">{{ "Date" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.rangeLabel }}</div>
                </div>
              </ng-container>
              <div
                *ngIf="category.type === 'Goal' && item.patientNote !== null && item.patientNote !== undefined"
                class="d-flex gap-3"
              >
                <div class="summary-key">{{ "Patient's Comment" | translate }}:</div>
                <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.patientNote }}</div>
              </div>
              <div
                *ngIf="category.type === 'Goal' && item.progress !== null && item.progress !== undefined"
                class="d-flex gap-3"
              >
                <div class="summary-key">{{ "Progress towards goal" | translate }}:</div>
                <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.progress }}</div>
              </div>
              <div
                *ngIf="
                  category.type === 'Questionnaire' &&
                  item.answers !== null &&
                  item.answers !== undefined &&
                  item.answers.length > 0
                "
                class="d-flex gap-3"
              >
                <div *ngFor="let answer of item.answers">
                  <div class="summary-key">{{ "Answer date" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">
                    {{ answer.date | date : "dd/MM/yyyy" }}
                  </div>
                  <div class="summary-key">{{ "Answer score" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">
                    {{ answer.selectedAnswerScore }}/{{ answer.selectedAnswerTotal }}
                  </div>
                </div>
              </div>
              <div *ngIf="item.frequencyLabel !== null && item.frequencyLabel !== undefined" class="d-flex gap-3">
                <div class="summary-key">{{ "activityItem.Frequency" | translate }}:</div>
                <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.frequencyLabel }}</div>
              </div>
              <div *ngIf="item.periodUnitLabel !== null && item.periodUnitLabel !== undefined" class="d-flex gap-3">
                <div class="summary-key">{{ "activityItem.PeriodUnit" | translate }}:</div>
                <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.periodUnitLabel }}</div>
              </div>
              <ng-container *ngIf="category.type === 'Observation' && item.timeOfDayLabel">
                <div class="d-flex gap-3">
                  <div class="summary-key">{{ "activityItem.ScheduleHours" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.timeOfDayLabel }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="category.type === 'MedicationRequest'">
                <div class="d-flex gap-3">
                  <div class="summary-key">{{ "addActivity.formLabels.DoseValue" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.doseLabel }}</div>
                </div>
                <div *ngIf="item.daysOfWeekLabel !== null && item.daysOfWeekLabel !== undefined" class="d-flex gap-3">
                  <div class="summary-key">{{ "exportPdfPage.daysOfWeek" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">
                    {{ item.daysOfWeekLabel }}
                  </div>
                </div>
                <div class="d-flex gap-3">
                  <div class="summary-key">{{ "activityItem.ScheduleHours" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.timeOfDayLabel }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="category.type === 'Education'">
                <div *ngIf="item.typeLabel !== null && item.typeLabel !== undefined" class="d-flex gap-3">
                  <div class="summary-key">{{ "educationPage.table.EducationType" | translate }}:</div>
                  <div class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.typeLabel }}</div>
                </div>
                <ng-container *ngIf="item.EducationalMaterial?.EducationType === 1">
                  <div class="d-flex gap-3">
                    <div class="summary-key">{{ "activityItem.Link" | translate }}:</div>
                    <a class="summary-value" style="font-weight: 500; margin-left: 10px">
                      {{ "www.youtube.com/embed/" + item.PathLabel }}
                    </a>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="item.EducationalMaterial?.EducationType === 0 || item.EducationalMaterial?.EducationType === 2"
                >
                  <div class="d-flex gap-3">
                    <div class="summary-key">{{ "activityItem.Link" | translate }}:</div>
                    <a class="summary-value" style="font-weight: 500; margin-left: 10px">
                      {{ serverPath + item.PathLabel }}
                    </a>
                  </div>
                </ng-container>
                <ng-container *ngIf="item.EducationalMaterial?.EducationType === 3">
                  <div class="d-flex gap-3">
                    <div class="summary-key">{{ "activityItem.Link" | translate }}:</div>
                    <a class="summary-value" style="font-weight: 500; margin-left: 10px">{{ item.PathLabel }}</a>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
