import { Component, OnInit } from "@angular/core";
import { PatientRecordService } from "./patient-record.service";
import { Helpers } from "src/app/services/app.helpers";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Role } from "src/app/core/enum/Role";
import enLocale from "@angular/common/locales/en-GB";
import elLocale from "@angular/common/locales/el";
import { registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { CarePlanService } from "./care-plan/care-plan.service";
import { ToastService } from "../../core/toast.service";
import { forkJoin } from "rxjs";
import { PatientsService } from "src/app/services/patients.service";
import { environment } from "../../../environments/environment";
import moment from "moment";
import { QuestionnairesService } from "../questionnaires/questionnaires.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-patient-record",
  templateUrl: "./patient-record.component.html",
  styleUrls: ["./patient-record.component.scss"],
})
export class PatientRecordComponent implements OnInit {
  activeTemplate = "dashboard";
  patientRestrictedAccess!: boolean;
  locale: any;
  items!: MenuItem[];
  activeIndex = 0;
  isInviteCaregiverFormVisible = false;
  isCaregiver = false;
  patientCareTeamPopupVisible = false;
  patientCareTeam: any[] = [];

  exportReportPdf: boolean = false;
  pdfModel: any;
  deployment: any;

  get patientId() {
    return this.patientRecordService.patientId;
  }

  get patientInfo() {
    return this.patientRecordService.patientInfo;
  }

  get wizardCompleted() {
    return true;
    // return this.patientRecordService.wizardCompleted;
  }

  constructor(
    private patientRecordService: PatientRecordService,
    public authenticationService: AuthenticationService,
    private translationService: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private careplanService: CarePlanService,
    private helpers: Helpers,
    private toastService: ToastService,
    private patientService: PatientsService,
    private questionnairesService: QuestionnairesService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.deployment = environment.deploymentSettings.deployment;

    this.isCaregiver = this.authenticationService.getTokenModel()?.isAttendedByCaregiver;
    registerLocaleData(enLocale, "en");
    registerLocaleData(elLocale, "el");
    this.locale = this.translationService.currentLang;

    this.translationService.onLangChange.subscribe(() => {
      this.locale = this.translationService.currentLang;
    });

    const queryTemplate = Helpers.getQueryStringParameter("t");
    if (queryTemplate !== undefined && queryTemplate !== "") this.activate(queryTemplate);
    this.patientRestrictedAccess =
      this.authenticationService.hasRole(Role.Doctor) || this.authenticationService.hasRole(Role.CareCoordinator);

    this.translationService.onLangChange.subscribe(() => {
      this.getItems();
    });

    this.getItems();
  }

  previous() {
    this.activeIndex--;
  }

  next() {
    this.activeIndex++;
  }

  isActive(name: any) {
    if (this.activeTemplate === name) return "active";
    return "";
  }

  activate(name: any) {}

  getLink(name: any) {
    if (!this.patientRestrictedAccess) {
      return `/patient-view/${name}`;
    } else {
      return `/patients/patient-record/${this.patientId}/${name}`;
    }
  }

  sendGoogleAnalytics(name: any) {
    if (!this.patientRestrictedAccess) {
      if (name === "medical-record") {
        this.googleAnalyticsService.eventEmitter("view_medical_record", "engagement", "patient");
      }
      if (name === "careplan/list") {
        this.googleAnalyticsService.eventEmitter("view_careplan", "engagement", "patient");
      }
    } else {
      if (name === "nutritional-habits") {
        this.googleAnalyticsService.eventEmitter("view_nutritional_habits", "engagement", "doctor");
      }
      if (name === "medical-record") {
        this.googleAnalyticsService.eventEmitter("view_medical_record", "engagement", "doctor");
      }
      if (name === "nutrition-notifications") {
        this.googleAnalyticsService.eventEmitter("view_nutrition_notifications", "engagement", "doctor");
      }
    }
  }

  getItems() {
    this.items = [
      {
        label: this.translationService.instant("general.account"),
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label:
          this.translationService.instant("medicalRecord.category.conditions") +
          " & " +
          this.translationService.instant("medicalRecord.category.medication"),
        command: (event: any) => {
          this.activeIndex = 1;
        },
      },
      {
        label:
          this.translationService.instant("medicalRecord.category.social_history") +
          " & " +
          this.translationService.instant("medicalRecord.category.family_history"),
        command: (event: any) => {
          this.activeIndex = 2;
        },
      },
      {
        label:
          this.translationService.instant("medicalRecord.category.allergies") +
          " & " +
          this.translationService.instant("medicalRecord.category.vaccines") +
          " & " +
          this.translationService.instant("medicalRecord.category.procedures"),
        command: (event: any) => {
          this.activeIndex = 3;
        },
      },
      {
        label: this.translationService.instant("general.button.texts.Submit"),
        command: (event: any) => {
          this.activeIndex = 3;
        },
      },
    ];
  }

  async finishWizard() {
    try {
      await this.patientRecordService.markWizardCompleted(true).toPromise();
      this.patientRecordService.wizardCompleted = true;
    } catch (err) {
      console.log(err);
    }
  }

  showInviteCaregiverForm() {
    this.isInviteCaregiverFormVisible = true;
  }

  async showPatientCareTeam() {
    try {
      this.patientCareTeam = (await this.careplanService.getPatientDoctors(this.patientId as any).toPromise()) as any;
      if (this.helpers.responseHasErrors(this.patientCareTeam, true)) throw new Error();
      this.patientCareTeamPopupVisible = true;
    } catch (err) {
      if (typeof err === "string") this.toastService.errorMessage(err);
    }
  }

  exportReport() {
    this.pdfModel = {};
    forkJoin({
      procedures: this.patientRecordService.getPatientProcedures(),
      medication: this.patientRecordService.getPatientMedication(),
      carePlan: this.patientService.getCarePlanActivities(this.patientId as string),
    }).subscribe((res) => {
      const questionnaires = res.carePlan.filter((x) => x.type === "Questionnaire")?.[0]?.values || [];
      if (questionnaires.length > 0) {
        const objToForkJoin: any = {};
        questionnaires.forEach((x) => {
          objToForkJoin[x.Id] = this.careplanService.getAllInOneForPeriod(
            this.patientId as string,
            x.StartDate.toString(),
            x.EndDate ? x.EndDate.toString() : moment().toISOString(),
            x.ResourceType,
            x.Id,
            x.ObservationCode,
            x.Timing,
            x.Period,
            x.PeriodUnit,
            x.Frequency
          );
        });
        forkJoin(objToForkJoin).subscribe((data: any) => {
          const answersToForkJoin: any = {};
          questionnaires.forEach((y) => {
            if (data[y.Id].numberOfObservationsThisPeriod > 0) {
              answersToForkJoin[y.Id] = this.questionnairesService.getQuestionnaireAnswers(parseInt(y.Id));
            }
          });
          forkJoin(answersToForkJoin).subscribe((answers: any) => {
            questionnaires.forEach((x) => {
              if (answers[x.Id]) {
                x.answers = answers[x.Id];
              }
            });
            this.pdfModel = res;
            this.exportReportPdf = true;
          });
        });
      } else {
        this.pdfModel = res;
        this.exportReportPdf = true;
      }
    });
  }

  pdfCreated() {
    this.exportReportPdf = false;
  }
}
