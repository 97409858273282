export const environment = {
  production: true,
  serverPath: "/rest",
  defaultLang: "en",
  dateDefaultFormat: "YYYY-MM-DD",
  sockets: {
    url: "/",
    path: "/ws",
    transports: ["websocket"],
  },
  daysForConsentExpirationNotification: 7,
  demoCredentials: {
    email: "",
    password: "",
  },
  analysisDemo: false,
  artifactAPI: "/service-catalog",
  artifactHUBUI: "http://160.40.53.131:8921",
  communities: "http://160.40.53.30:4011/communities",
  swagger: "https://raise-platform.fotisn-certh.ddns.net/api-docs/",
  kubernetesDashboard: "https://dashboard.fotisn-certh.ddns.net/#/login",
  agentUrl: "wss://heca.iti.gr/assistant-api/ws/",
  agentKnowledgebaseId: "88DA1567-0638-1166-3C32-D2A31342A82E",
  deploymentSettings: {
    deployment: "en",
  },
};
