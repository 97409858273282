import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IQuestionnaire, IQuestionaireHeader, IQuestionnaireAnswer } from "./questionnaires.model";
import * as moment from "moment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class QuestionnairesService {
  private readonly endpoint = "api/questionnaires";
  constructor(private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<Array<IQuestionaireHeader>>(`${environment.serverPath}/${this.endpoint}`);
  }

  getAllDoctorsQuestionnaires() {
    return this.httpClient.get<Array<IQuestionaireHeader>>(
      `${environment.serverPath}/${this.endpoint}/get-all-doctors-questionnaires`
    );
  }

  getById(questionnaireId: number) {
    return this.httpClient.get<IQuestionaireHeader>(`${environment.serverPath}/${this.endpoint}/${questionnaireId}`);
  }

  create(model: IQuestionaireHeader) {
    return this.httpClient.post(`${environment.serverPath}/${this.endpoint}`, model);
  }

  edit(model: IQuestionaireHeader, questionnaireId: number) {
    return this.httpClient.put(`${environment.serverPath}/${this.endpoint}/${questionnaireId}`, model);
  }

  deleteQuestionnaire(questionnaireId: number) {
    return this.httpClient.delete(`${environment.serverPath}/${this.endpoint}/${questionnaireId}`);
  }

  saveQuestionaireAnswers(questionnaireId: number, questionnaire: IQuestionnaire, answeredBy: any = null) {
    return this.httpClient.post(`${environment.serverPath}/${this.endpoint}/answers`, {
      QuestionnaireId: questionnaireId,
      Date: new Date(),
      Data: questionnaire,
      AnsweredBy: answeredBy ? answeredBy.fhirId : null,
      AnsweredByResourceType: answeredBy ? "Practitioner" : null,
      PatientId: answeredBy ? answeredBy.patientId : null,
    });
  }

  getQuestionnaireAnswers(questionnaireId: number) {
    return this.httpClient.get<Array<IQuestionnaireAnswer>>(
      `${environment.serverPath}/${this.endpoint}/answers/${questionnaireId}`
    );
  }

  getDoctorQuestionnaireAnswers(questionnaireId: number) {
    return this.httpClient.get<Array<IQuestionnaireAnswer>>(
      `${environment.serverPath}/${this.endpoint}/doctor-questionnaire-answers/${questionnaireId}`
    );
  }
}
