import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Chart, ChartOptions } from "chart.js";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { MessageBroadcasterService } from "src/app/services/message.service";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "../../../services/authentication.service";
import { ToastService } from "../../toast.service";
import { GarrySession, GarrySessionData } from "./streaming-examinations.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-streaming-examinations",
  templateUrl: "./streaming-examinations.component.html",
  styleUrls: ["./streaming-examinations.component.scss"],
})
export class StreamingExaminationsComponent implements OnInit, OnDestroy {
  @Input() userId!: string;

  activeExaminationId!: string | null;
  showStreamingPopUp = false;
  newStreamingExaminationDataSubscription!: Subscription;
  endStreamingExaminationSubscription!: Subscription;

  lineChartPitch!: Chart;
  lineChartVolume!: Chart;
  optionsLineChart: any = {
    responsive: true,
  };

  pitchDataset: any;

  volumeDataset: any;

  summary!: Partial<GarrySession> | null;
  sessions!: GarrySession[];
  progressBarValue!: number;
  interval!: NodeJS.Timer | undefined;

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private messageService: MessageBroadcasterService,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    this.pitchDataset = {
      labels: [],
      datasets: [{ data: [], fill: false, label: this.translationService.instant("Pitch") }],
    };
    this.volumeDataset = {
      labels: [],
      datasets: [
        {
          backgroundColor: "#6cdd02",
          borderColor: "#6cdd02",
          pointRadius: 0,
          data: [],
          fill: false,
          label: this.translationService.instant("Volume"),
        },
      ],
    };
    this.getOldSessions();
    this.newStreamingExaminationDataSubscription = this.messageService.newStreamingExaminationData.subscribe(
      (x: Partial<GarrySessionData>[]) => {
        x.forEach((value) => {
          this.volumeDataset.labels.push(value.time?.toString());
          this.volumeDataset.datasets[0].data.push(value.volume);

          this.pitchDataset.labels.push(value.time?.toString());
          this.pitchDataset.datasets[0].data.push(value.pitch);
        });

        this.lineChartVolume.update();
        this.lineChartPitch.update();
      }
    );
    this.endStreamingExaminationSubscription = this.messageService.endStreamingExamination.subscribe(
      (x: Partial<GarrySession>) => {
        if (this.interval) {
          this.progressBarValue = 100;
          clearInterval(this.interval);
          this.interval = undefined;
        }

        if (x && x?.minimumPitch) {
          this.summary = {
            minimumPitch: x.minimumPitch,
            averagePitch: x.averagePitch,
            maximumPitch: x.maximumPitch,
            minimumVolume: x.minimumVolume,
            averageVolume: x.averageVolume,
            maximumVolume: x.maximumVolume,
            recordingTime: x.recordingTime,
          };
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyActiveSession();
    this.newStreamingExaminationDataSubscription.unsubscribe();
    this.endStreamingExaminationSubscription.unsubscribe();
  }

  async getOldSessions() {
    this.httpClient
      .get<GarrySession[]>(`${environment.serverPath}/api/examination-streaming/user-sessions/${this.userId}`)
      .subscribe({
        next: (data) => {
          this.sessions = data;
        },
        error: (error) => {
          this.toastService.generalErrorMessage();
        },
      });
  }

  startStreamingSession() {
    this.httpClient
      .post<GarrySession>(`${environment.serverPath}/api/examination-streaming/session/front`, {
        userId: this.userId,
        creatorId: this.authService.getUserId(),
      })
      .subscribe({
        next: (data) => {
          let time = moment(data.createdAt);
          this.activeExaminationId = data.examinationId;
          this.progressBarValue = 0;
          let now = moment();
          let diff = Math.abs(now.diff(time, "seconds", false));
          this.progressBarValue = Math.floor((10 * 60 - diff) / (10 * 60));

          this.interval = setInterval(() => {
            this.progressBarValue = this.progressBarValue + 1 / 6;
            if (this.progressBarValue >= 100) {
              this.progressBarValue = 100;
              clearInterval(this.interval);
              this.interval = undefined;
              this.destroyActiveSession();
              this.showStreamingPopUp = false;
            }
          }, 1000);
          this.showStreamingPopUp = true;

          this.renderGraph();
        },
        error: (error) => {
          this.toastService.generalErrorMessage();
        },
      });
  }

  destroyActiveSession() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
      this.progressBarValue = 0;
    }
    this.summary = null;
    this.pitchDataset = {
      labels: [],
      datasets: [{ data: [], fill: false, label: this.translationService.instant("Pitch") }],
    };
    this.volumeDataset = {
      labels: [],
      datasets: [{ data: [], fill: false, label: this.translationService.instant("Volume") }],
    };

    this.activeExaminationId &&
      this.httpClient
        .delete<string>(`${environment.serverPath}/api/examination-streaming/session/${this.activeExaminationId}`)
        .subscribe({
          next: (data) => {
            this.activeExaminationId = null;
            this.getOldSessions();
            this.showStreamingPopUp = false;
          },
          error: (error) => {
            this.toastService.generalErrorMessage();
          },
        });
  }

  renderGraph() {
    if (this.lineChartPitch) {
      this.lineChartPitch.destroy();
      setTimeout(() => {
        this.lineChartPitch = new Chart("chart-pitch", {
          type: "scatter",
          data: this.pitchDataset,
          options: {
            ...this.optionsLineChart,
            tooltips: {
              intersect: false,
              mode: "index",
              callbacks: {
                label: (context: any) => {
                  let label = context.dataset.data[context.dataIndex].label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.dataset.data[context.dataIndex].value;
                  // if (this.report.datasets[context.dataIndex].unit) {
                  //   label += " " + this.report.datasets[context.dataIndex].unit;
                  // }
                  return label;
                },
              },
            },
          },
        });
      }, 300);
    } else {
      setTimeout(() => {
        this.lineChartPitch = new Chart("chart-pitch", {
          type: "scatter",
          data: this.pitchDataset,
          options: {
            ...this.optionsLineChart,
            tooltips: {
              intersect: false,
              mode: "index",
              callbacks: {
                label: (context: any) => {
                  let label = context.dataset.data[context.dataIndex].label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.dataset.data[context.dataIndex].value;
                  // if (this.report.datasets[context.dataIndex].unit) {
                  //   label += " " + this.report.datasets[context.dataIndex].unit;
                  // }
                  return label;
                },
              },
            },
          },
        });
      }, 300);
    }

    if (this.lineChartVolume) {
      this.lineChartVolume.destroy();
      setTimeout(() => {
        this.lineChartVolume = new Chart("chart-volume", {
          type: "line",
          data: this.volumeDataset,
          options: this.optionsLineChart,
        });
      }, 300);
    } else {
      setTimeout(() => {
        this.lineChartVolume = new Chart("chart-volume", {
          type: "line",
          data: this.volumeDataset,
          options: this.optionsLineChart,
        });
      }, 300);
    }
  }

  removeItemClick(event: any) {
    this.toastService.confirmDelete(() => {
      this.httpClient
        .delete<string>(`${environment.serverPath}/api/examination-streaming/user-sessions/${event.examinationId}`)
        .subscribe({
          next: (data) => {
            this.toastService.infoMessage(this.translationService.instant("alerts.DELETE_SUCCESS"));
            this.getOldSessions();
          },
          error: (error) => {
            this.toastService.generalErrorMessage();
          },
        });
    });
  }
}
