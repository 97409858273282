import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as fhir from "fhir-stu3";
import { PatientDTO } from "../models/patient.model";

@Injectable({
  providedIn: "root",
})
export class DoctorService {
  deployment!: string;
  constructor(private httpClient: HttpClient) {
    this.deployment = environment.deploymentSettings.deployment;
  }

  getAllCriticalEvents() {
    return this.httpClient.get<Array<any>>(`${environment.serverPath}/api/doctors/critical-notifications`);
  }

  public getPatientsWithConsent() {
    const deploymentCountry = this.deployment;

    let url = "/api/doctors/patients-with-consent";
    if (deploymentCountry === "es") {
      url = "/api/doctors/all-patients";
    }
    return this.httpClient.get<Array<fhir.Patient>>(`${environment.serverPath}${url}`);
  }

  public getDemoPatients() {
    return this.httpClient.get<Array<PatientDTO>>(`${environment.serverPath}/api/doctors/demo-patients`);
  }

  public getAllDoctors() {
    return this.httpClient.get<Array<fhir.Practitioner>>(`${environment.serverPath}/api/doctors/all`);
  }
}
