import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { React2Item } from "./react2/react2.model";

export interface TactusRecord {
  id: number;
  app: TactusApps;
  data: string;
  date: Date;
  patientId: number;
}

export enum TactusApps {
  Category,
  ApraxiaTherapy,
  ConversationTherapy,
  AdvancedNaming,
  AdvancedReading,
  AdvancedComprehension,
  AdvancedWriting,
}

@Injectable({
  providedIn: "root",
})
export class ApplicationScoresService {
  constructor(private httpClient: HttpClient) {}

  public addTactusRecord(patientId: string, model: Partial<TactusRecord>) {
    return this.httpClient.post<TactusRecord>(`${environment.serverPath}/api/tactus/${patientId}`, {
      model,
    });
  }

  public editTactusRecord(id: number, model: Partial<TactusRecord>) {
    return this.httpClient.put<TactusRecord>(`${environment.serverPath}/api/tactus/${id}`, {
      model,
    });
  }

  public deleteTactusRecord(id: number) {
    return this.httpClient.delete<boolean>(`${environment.serverPath}/api/tactus/${id}`);
  }

  public getAllTactusRecords(patientId: string) {
    return this.httpClient.get<TactusRecord[]>(`${environment.serverPath}/api/tactus/${patientId}`);
  }

  public getAllReact2Records(patientId: string) {
    return this.httpClient.get<React2Item[]>(
      `${environment.serverPath}/api/observations/add-external-observation/react2/${patientId}`
    );
  }

  public getReHubData(patientId: string) {
    return this.httpClient.get<{ done: number; pending: number }>(
      `${environment.serverPath}/api/catalog/get-rehub-data/${patientId}`
    );
  }
}
